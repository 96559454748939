import * as Sentry from "@sentry/react";
import ApiService from "./ApiService"


function initializeSentry() {
  Sentry.init({
    dsn: "https://51d95366d62fcb98c40ba0bf14c612a8@o4507796690501632.ingest.us.sentry.io/4508170469965824",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/citybattles\.com/, /^https:\/\/biz-battles-staging-1e30524c2d72\.herokuapp\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  // set user
  if (IS_AUTHENTICATED) {
    Sentry.setUser({ email: USER_EMAIL });
  } else {
    Sentry.setUser(null);
  }
}

function setUserTimezoneIfNeeded() {
  if (IS_AUTHENTICATED === true) {
    const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currTimezone !== USER_TIMEZONE) {
      ApiService.userSetTimezone(currTimezone);
    }
  }
}

// Run on every page load
$(document).ready(function() {
  initializeSentry();
  setUserTimezoneIfNeeded();

  // to test sentry
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('error') === "1") {
    throw new Error("blah blah");
  }
});
